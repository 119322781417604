import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you have just started using GitHub and contributing to an Open Source project, you might come to a point in which with every new Pull Request, all your previous commits will be displayed instead of just the latest commit.`}</p>
    <p>{`I was sitting on a 44 commit history, wondering why the hell all my commits were showing every time I did a new Pull Request. Eventually, I have learned how to fix this issue and why this was happening.`}</p>
    <h1 {...{
      "id": "git-rebase",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#git-rebase",
        "aria-label": "git rebase permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Git Rebase`}</h1>
    <p>{`With rebase, you can move, combine and drop one or more commits.`}</p>
    <p>{`Let's imagine that you finish working on a feature. You commit, push and create a pull request to the main repo. Then some of the tests fail because you have a line that's too long, a typo in the docstring and there's an extra empty line added at the end of the file. `}</p>
    <p>{`You go back, fix the issues, commit and push these new changes.`}</p>
    <p>{`If you go back to your Pull Request you will see that a new commit was added to it. But since the changes that you have just committed are just a fix to the initial commit, you probably want to just show that one commit. Rebasing will help you do that.`}</p>
    <p>{`By using the command `}<inlineCode parentName="p">{`git rebase -i HEAD~2`}</inlineCode>{` you can rebase the last two commits into one. `}</p>
    <p>{`As soon as you type that command a text editor (usually vim) will appear and show you a list of the commits and commands that you can use. By default, every commit starts with the command `}<inlineCode parentName="p">{`pick`}</inlineCode>{` which means that you will use the commit on your rebase process. `}</p>
    <p>{`If you want to join two commits into one you can either use the `}<inlineCode parentName="p">{`squash`}</inlineCode>{` or `}<inlineCode parentName="p">{`fixup`}</inlineCode>{` command. `}</p>
    <p><em parentName="p">{`Note: If you are new to vim, simply press the i-key to enter insert mode to write. Press ESC to exit insert mode and to save the changes simply press `}<inlineCode parentName="em">{`:`}</inlineCode>{` and write the command `}<inlineCode parentName="em">{`wq!`}</inlineCode>{` (write, quit, override).`}</em></p>
    <p>{`When you finish the rebase process you will have to force push your changes. Simply use the command `}<inlineCode parentName="p">{`git push -f`}</inlineCode>{` to force push the changes. If you go back to your Pull Request you will see that only one commit message is shown.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.atlassian.com/git/tutorials/rewriting-history/git-rebase"
      }}>{`Read more about rebasing`}</a></p>
    <h1 {...{
      "id": "git-pull",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#git-pull",
        "aria-label": "git pull permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Git Pull`}</h1>
    <p>{`The command `}<inlineCode parentName="p">{`git pull`}</inlineCode>{` does two commands in one: git fetch and git merge.`}</p>
    <p>{`The first thing that git pull does is importing commits from a remote repo (your own GitHub repository or the main one) into your local repository. If the project that you are working on has other contributors and there have been new features or bug fixes added to the main repository, it's quite normal that your local repo will get behind and miss these important new changes.`}</p>
    <p>{`To avoid this you should use the command `}<inlineCode parentName="p">{`git pull upstream master`}</inlineCode>{` to get the latest changes made to the main repo and merge them into yours.`}</p>
    <p>{`You can also use the flag `}<inlineCode parentName="p">{`--rebase`}</inlineCode>{` if you want to avoid getting an empty commit from the merge.`}</p>
    <h1 {...{
      "id": "the-solution",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-solution",
        "aria-label": "the solution permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Solution`}</h1>
    <p>{`The solution to keep your commit history down is quite simple. All you need to do is to run the command `}<inlineCode parentName="p">{`git pull upstream master`}</inlineCode>{` to pull all the changes made to the main repo and make your local repository up to date. Then all you need to do is push the changes into your GitHub repository with a simple `}<inlineCode parentName="p">{`git push`}</inlineCode>{`.`}</p>
    <p>{`If you have a huge commit history like me (about 44 commits) you will have to spend a large amount of time fixing conflicts on the rebase process. Eventually, everything will be up to date and that huge list of commits won't show up anymore when you do a Pull Request.`}</p>
    <p>{`Hope this will help you in both keeping your commit history down and gives some clarification as to why there was such a long one.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      